<style lang="scss">
.jobportfolio {
  .portfolio-box {
    margin-top: 50px;
    [class*='col-'] {
      justify-items: center;
      align-self: center;
    }

    .bild-box {
      img {
        display: block;
        margin: 0 auto;
      }
      @media (max-width: 992px) {
        margin-bottom: 20px;
      }
    }
  }
  h2 {
    margin-top: 0;
  }
  ul {
    margin: 10px 0 !important;
    li {
      margin: 5px 0 !important;
    }
  }

  .preis-box {
    padding: 5px 0;
    margin-bottom: 10px;

    span {
      display: inline-block;
      font-style: italic;
      padding: 8px 10px;
      border-top: 1px solid #eee;
      border-bottom: 3px solid #eee;
      strong {
        display: inline-block;

        font-weight: 400;
        margin-left: 5px;
      }
    }
  }
  .wussten-sie-box {
    img {
      display: block;
      margin: 10px auto;
    }
  }
}
</style>

<template>
  <div class="content jobportfolio container">
    <h1>Job-Portfolio</h1>
    <p class="intro-txt">Moderne Zeiten erfordern moderne Maßnahmen und so haben wir auch am Karriere-Markt ein aufregendes Produktsortiment im Angebot.</p>

    <div class="portfolio-box">
      <div class="row">
        <div class="col-lg-6">
          <div class="bild-box">
            <img src="@/assets/img/img-content/digital/d-job-tracker.jpg" alt="Job Tracker" class="img-fluid shadow" />
          </div>
        </div>
        <div class="col-lg-6">
          <h2>Job-Ticker</h2>
          <p>Wussten Sie, dass sich 60 % der Österreicher für Job-Anzeigen interessieren, ohne aktiv danach auf Job-Portalen suchen? Nutzen Sie dieses Potenzial mit dem neuen Job-Ticker der Kleinen Zeitung und machen Sie auf Ihr Angebot aufmerksam.</p>
          <div class="preis-box">
            <span>Produktpreis ab <strong>€ 79,00 </strong></span>
          </div>
          <CollapsibleButton link="job-ticker-collapse" class="content-link-mehrinfo"><span class="material-icons">arrow_right_alt</span>Alle Informationen zum Job-Ticker</CollapsibleButton>
        </div>
      </div>
    </div>
    <Collapsible link="job-ticker-collapse">
      <div class="row">
        <div class="col-lg-6 order-lg-2 justify-content-center align-self-center">
          <div class="bild-box">
            <img src="@/assets/img/img-content/digital/d-job-pakete.jpg" alt="Job Pakete" class="img-fluid shadow" />
          </div>
        </div>
        <div class="col-lg-6 justify-content-center align-self-center">
          <p>Ihre digitale Job-Zeilenanzeige als „News“-Ticker mit aktuellen Stellenanzeigen aus der Region. Erreichen Sie mit dem Job-Ticker die latent Job-Interessierten, die für Ihr Stellenagebot in Frage kommen, aber nicht aktiv auf einem Job-Portal nach Ihrer Anzeige suchen.</p>
          <p><strong>Platzierung</strong><br />Wirtschaft, Karriere, Regionalseiten</p>
        </div>
      </div>
      <h3>Leistung &amp; Tarif</h3>
      <div class="row">
        <div class="col-lg-6 justify-content-center align-self-center">
          <ul class="content-ul">
            <li>Bis zu 20 Jobs im Job-Ticker platzieren</li>
            <li>Abbildung inkl. Firmenname, Job-Bezeichnung, Standort, Call-to-Action + Verlinkung Unternehmenswebsite</li>
          </ul>
        </div>
        <div class="col-lg-6 justify-content-center align-self-center">
          <div class="preis-box">
            <span><strong>€ 79,00</strong> in der ersten Woche, jede weitere Woche <strong>€ 49,99</strong></span>
          </div>
        </div>
      </div>
    </Collapsible>
    <div class="portfolio-box">
      <div class="row">
        <div class="col-lg-6 order-lg-2 justify-content-center align-self-center">
          <div class="bild-box">
            <img src="@/assets/img/img-content/digital/d-job-pakete.jpg" alt="Job Pakete" class="img-fluid shadow" />
          </div>
        </div>
        <div class="col-lg-6 justify-content-center align-self-center">
          <h2>Programmatic Job Ads</h2>
          <p>Branchenspezifisches Content-Targeting über das gesamte programmatische Netzwerk rundet Ihre Raum-bzw. Zeilenanzeige ab. Erreichen Sie auch jene, die nicht aktiv suchen. Das mit minimalen Aufwand für Sie.</p>
          <div class="preis-box">
            <span>Produktpreis ab <strong>€ 249,00 </strong></span>
          </div>
          <CollapsibleButton link="job-pakete-collapse" class="content-link-mehrinfo"><span class="material-icons">arrow_right_alt</span>Alle Informationen zu den Programmatic Job-Ads </CollapsibleButton>
        </div>
      </div>
    </div>
    <Collapsible link="job-pakete-collapse">
      <h3>Nutzen Sie die Möglichkeit auf Ihre Stellenangebote auch programmatisch aufmerksam zu machen</h3>
      <div class="row wussten-sie-box">
        <div class="col-lg-12"><strong>Wussten Sie, dass ...</strong></div>
        <div class="col-lg-6">
          <div class="row margin-30-0">
            <div class="col-lg-4 justify-content-center align-self-center">
              <img src="@/assets/img/img-content/digital/job-lampe.png" alt="Job Lampe" class="img-fluid " />
            </div>
            <div class="col-lg-8 justify-content-center align-self-center">
              <p>
                <strong>60 % latent Job-Interessierte<sup>1</sup></strong> für Ihr Stellenagebot in Frage kommen, die nicht aktiv auf einem Job-Portal nach Ihrer Anzeige suchen?
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="row margin-30-0">
            <div class="col-lg-4 justify-content-center align-self-center">
              <img src="@/assets/img/img-content/digital/job-auto.png" alt="Job Lampe" class="img-fluid " />
            </div>
            <div class="col-lg-8 justify-content-center align-self-center">
              <p>
                <strong>77% der Berufstätigen<sup>2</sup></strong> für Ihren Traumjob maximal 50 km pendeln würden?
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-12">
          <p>Mit unseren digitalen Job-Produkten erreichen Sie genau diese Zielgruppe und erzeugen zusätzliche Aufmerksamkeit für Ihr Stellenangebot.</p>
        </div>
      </div>
      <div class="row margin-50-0">
        <div class="col-lg-6 justify-content-center align-self-center">
          <img src="@/assets/img/img-content/digital/job-quick.png" alt="Job Quick" class="img-fluid img-center " />
        </div>
        <div class="col-lg-6 justify-content-center align-self-center">
          <h3>Quick</h3>
          <ul class="content-ul">
            <li>Werbemittel: <strong>im Design-Template</strong></li>
            <li>Werbemittelerstellung <strong>automatisiert</strong></li>
            <li>Targeting: <strong>regional</strong></li>
            <li>Kampagnenerstellung: <strong>automatisiert</strong></li>
            <li>Kampagnenstart: <strong>1. od. 15. des Monats</strong></li>
            <li>Laufzeit: <strong>14 Tage</strong></li>
          </ul>
          <div class="preis-box">
            <span><strong>€ 249,00</strong></span>
          </div>
        </div>
      </div>
      <div class="row margin-50-0">
        <div class="col-lg-6 justify-content-center align-self-center">
          <img src="@/assets/img/img-content/digital/job-flexible.png" alt="Job Flexible" class="img-fluid img-center " />
        </div>
        <div class="col-lg-6 justify-content-center align-self-center">
          <h3>Flexible</h3>
          <ul class="content-ul">
            <li>Werbemittel: <strong>im individuellen Design</strong></li>
            <li>Werbemittelerstellung <strong>kostenlos im Creative Generator</strong> oder <strong>Selbstanlieferung</strong></li>
            <li>Targeting: <strong>individuell wählbar</strong> (vorzugsweise regional)</li>
            <li>Kampagnenerstellung: <strong>selbstständig</strong></li>
            <li>Kampagnenstart: <strong>frei wählbar</strong></li>
            <li>Laufzeit: <strong>flexibel</strong></li>
          </ul>
          <div class="preis-box">
            <span><strong>€ 500,00</strong></span>
          </div>
        </div>
      </div>
      <div class="row margin-50-0">
        <div class="col-lg-6 justify-content-center align-self-center">
          <img src="@/assets/img/img-content/digital/job-premium.png" alt="Job Premium" class="img-fluid img-center " />
        </div>
        <div class="col-lg-6 justify-content-center align-self-center">
          <h3>Premium</h3>
          <ul class="content-ul">
            <li>Werbemittel <strong>individuell nach Ihren Wünschen erstellt</strong></li>
            <li>Werbemittelerstellung: <strong>durch Design- Profis der Kleinen Zeitung</strong></li>
            <li>Targeting: <strong>individuell wählbar</strong> (vorzugsweise regional)</li>
            <li>Kampagnenerstellung: <strong>serviciert</strong></li>
            <li>Kampagnenstart: <strong>frei wählbar</strong></li>
            <li>Laufzeit: <strong>4 Wochen</strong></li>
          </ul>
          <div class="preis-box">
            <span><strong>€ 699,00</strong></span>
          </div>
        </div>
      </div>
      <div class="info-txt-preis">Alle Preise verstehen sich in Euro. Fixpaket kann nicht rabattiert werden.<br />*Inkludierte Leistungen: Programmatic € 500,- ; Werbemittelerstellung: € 295,-; Servicierung der Kampagne: € 55,- <strong>Tarifwert: € 850,-.</strong></div>
      <br /><br />
      <p class="text-center">Für weitere Informationen kontaktieren Sie bitte Ihren Kleine Zeitung Berater.</p>
      <div class="quelle-txt text-center d-block">
        <sup>1</sup> Internetstudie MindTake 360: We Are Developers-Studie 2019<br />
        <sup>2</sup> Internetstudie Jänner 2020 vom Marktforschungsinstitut Innofact AG für ImmobilienScout24
      </div>
    </Collapsible>
    <div class="portfolio-box">
      <div class="row">
        <div class="col-lg-6 justify-content-center align-self-center">
          <div class="bild-box">
            <img src="@/assets/img/img-content/digital/jobs-klz-willhaben.jpg" alt="Kleine Zeitung + Willhaben" class="img-fluid" />
          </div>
        </div>
        <div class="col-lg-6 justify-content-center align-self-center">
          <h2>willhaben.at</h2>
          <p>Profitieren Sie von der optimalen Kombination „Kleine Zeitung“ und „willhaben“. Mit der reichweitenstärksten Tageszeitung im Süden Österreichs und dem größten digitalen Marktplatz des Landes nutzen Sie das erfolgreichste Kommunikationsnetzwerk Österreichs.</p>

          <CollapsibleButton link="job-willhaben-collapse" class="content-link-mehrinfo"><span class="material-icons">arrow_right_alt</span>Alle Informationen zu den Programmatic Job-Paketen </CollapsibleButton>
        </div>
      </div>
    </div>
    <Collapsible link="job-willhaben-collapse">
      <h3>Ihre Werbemöglichkeiten als Ad-On zur Kleinen Zeitung.</h3>
      <div class="row">
        <div class="col-lg-6 justify-content-center align-self-center">
          <div class="tabelle-box alignItems">
            <div class="headline"><strong>Stellenanzeige</strong> mit Ihrem Logo / Bild inkl. Vorreihung</div>
            <ul class="content-ul">
              <li>Anzeige selbst erstellen und verwalten</li>
              <li>Layoutvorlagen zur Auswahl</li>
              <li>Optimiert für alle Endgeräte</li>
              <li>60 Tage Laufzeit mit wöchentlicher Vorreihung</li>
            </ul>
            <div class="row head-row">
              <div class="col-lg-6 col-6 text-align-left">Jobanzeige</div>
              <div class="col-lg-6 col-6 text-align-right">Tarif</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-6 col-6 text-align-left">1 Anzeige</div>
              <div class="col-lg-6 col-6 text-align-right">300,00</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-6 col-6 text-align-left">3 Anzeigen</div>
              <div class="col-lg-6 col-6 text-align-right">828,00</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-6 col-6 text-align-left">5 Anzeigen</div>
              <div class="col-lg-6 col-6 text-align-right">1290,00</div>
            </div>
          </div>
          <!-- -->
          <div class="tabelle-box alignItems">
            <div class="headline"><strong>Print Plust Zeilenanzeige</strong> Self-Service</div>
            <ul class="content-ul">
              <li>Ihre Zeilenanzeige wird zusätzlich 60 Tage auf willhaben inkl. 14-tätiger Vorreihung abgebildet.</li>
            </ul>

            <div class="row head-row">
              <div class="col-lg-6 col-6 text-align-left">Laufzeit</div>
              <div class="col-lg-6 col-6 text-align-right">Tarif</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-6 col-6 text-align-left">60 Tage</div>
              <div class="col-lg-6 col-6 text-align-right">150,00</div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 justify-content-center align-self-center">
          <div class="bild-box">
            <img src="@/assets/img/img-content/digital/willhaben-stellenanzeige.jpg" alt="Willhaben Stellenanzeige" class="img-fluid" />
          </div>
        </div>
      </div>
    </Collapsible>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CollapsibleButton from '@/components/util/CollapsibleButton.vue';
import Collapsible from '@/components/util/Collapsible.vue';

export default defineComponent({
  name: 'JobPortfolio',
  components: {
    CollapsibleButton,
    Collapsible,
  },
});
</script>
